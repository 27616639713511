import React from "react"
import {
  useColorModeValue,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorSwitcher"

export default function Navbar(){
  const bg = useColorModeValue('black', '#ffff');
  return (
    <>
      <Box position="fixed" px={5} boxShadow="md" w="100%">
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Text fontWeight="bold">Luna</Text>
          <Flex alignItems={"center"}>
            <ColorModeSwitcher />
          </Flex>
        </Flex>
        <Box
          bg={bg}
          backdropFilter="blur(10px)"
          webkitBackdropFilter="blur(10px)"
          position="relative"
        ></Box>
      </Box>
    </>
  )
}