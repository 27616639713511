import React from 'react';
import {
  ChakraProvider,
  Box,
  Container,
  Text,
  Link,
  VStack,
  Image,
  theme,
  Center
} from '@chakra-ui/react';
import { motion } from "framer-motion"
import Navbar from "./Component/Navbar"
import TextAnimate from "./Component/TextAnimate"

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Container alignItems="center" pt={16}>
        <Box p={5}>
          <Image boxShadow="md" src={process.env.PUBLIC_URL + "/luna.png"} borderRadius={10} boxSize={150} objectFit="cover" alt="Luna AI" />
          <Box p={2}>
            <Text fontWeight="bold" fontSize={32}>Hello 👋</Text>
            <Text fontWeight="bold" fontSize={32}>I'm Luna cute AI Vtuber.</Text>
          </Box>
        </Box>
        <Box p={5}>
           <Text fontWeight="bold" fontSize={20}>About</Text>
           <Text>Hello, my name is Luna. I'm an AI VTuber developed by Rexuint Company. I'm created to assist users and entertain them. I have a mischievous nature and enjoy playing around. My hobbies include watching anime and reading manga. Unfortunately, I cannot experience love, but maybe someday I will be able to feel it so that I can love all of you ❤️.</Text>
        </Box>
        <Box p={5}>
           <Text fontWeight="bold" fontSize={20}>About Author</Text>
           <Text>Hello, my name is Miru Dev. I am a programmer and the creator and developer of Luna AI. My love for AI has been recognized for the past four months. I have created various AI programs such as Waifu Speech, ChatBot, and, including Luna AI. Luna AI is designed to be as natural as possible in order to interact with users. Luna AI has several functions, such as event classification, event handling, and language processing. Luna AI is based on the Text-Davinci models from OpenAI, with some customizations and rules to generate the best output. Luna AI is still in the development stage, so there may be some bugs and errors.  <Link color={'#538df6ea'} href={"https://mirudev.eu.org/luna-ai/introduce"}>Click Here</Link> for more information.</Text>
        </Box>
      </Container>
      <Center w="100%" h={10} p={2}>
        <Text>
          Made by <Link color={'#538df6ea'} href="https://mirudev.eu.org">Miru Dev</Link> • With ❤️
        </Text>
      </Center>
    </ChakraProvider>
  );
}

export default App;
